import React from 'react';

const Button = props => (
  <div onClick={props.onClick} onKeyPress={props.onKeyPress} className={`${props.className}`}> {/* eslint-disable-line */}
    <a href={props.link} target="_blank" rel="noopener noreferrer" className={`button px-3 ${props.buttonClassName}`}>
      {props.text}
    </a>
  </div>
);

export default Button;
