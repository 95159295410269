import {isInBrowser} from './helper';

export function getItemFromStorage(key, defaultValue) {
  if (isInBrowser) {
    const data = typeof window !== 'undefined' && window.localStorage.getItem(key);
    if (data === null) {
      return defaultValue;
    } else return data;
  }
}

export function setItemToStorage(key, value) {
  if (isInBrowser) {
    typeof window !== 'undefined' && window.localStorage.setItem(key, value);
  }
}
