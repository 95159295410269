import logo from 'src/images/logo.png';
import dibawahtangga from 'src/images/app/IZIFILL_dbt.png';
import workcoffee from 'src/images/app/IZIFILL_work.png';
import sman3bdg from 'src/images/app/IZIFILL_SMAN3bdg.png';
import sman8jkt from 'src/images/app/IZIFILL_SMAN8jkt.png';
import mrtj from 'src/images/app/IZIFILL_MRTj.png';
import neduh from 'src/images/app/IZIFILL_neduh.png';
import bluebird from 'src/images/app/IZIFILL_wwf_bluebird.png';
import smkn3denpasar from 'src/images/app/IZIFILL_SMKN3dps.png';
import udjo from 'src/images/app/IZIFILL_udjo.png';
import waterForum from 'src/images/app/IZIFILL_water_forum.png';
import kedaiutama from 'src/images/app/IZIFILL_kedaiutama.png';
import smkMerdeka from 'src/images/app/IZIFILL_SMKMerdeka.png';

const TempEnum = {HOT: 'hot', WARM: 'warm', CHILL: 'chill'};
const ERROR_LOGIN = {
  EMPTY_FIELD: 'There is empty field(s) in above form',
  WRONG_PASSWORD: 'Failed, username / password didn\'t match',
  SERVER_ERROR: 'Error from server, try in several minutes or change your username',
  CONFIRM_PASSWORD_NOT_MATCH: 'password didn\'t match',
};

export const IZIFILL_NAME_KEY = 'izifillName';
export const IZIFILL_NAME_DEFAULT_VALUE = 'Izipeople';
export const IZIFILL_EMAIL_KEY = 'izifillEmail';

export const STATION_CODE_KEY = 'stationCode';

export const UNAUTHORIZED_STATUS_CODE = 401;

export {TempEnum, ERROR_LOGIN};

export const DEMO_STATIONS = ['demo', 'wakafxl'];
export const UNLIMITED_REFILL_STATIONS = ['bluebird1', 'bluebird2', ,'test-free'];
export const GALON_BASED_STATIONS = ['udjo', 'smkn3denpasar', 'bluebird1', 'bluebird2', 'test-free', 'biologi-ui'];
export const STATIONS_LOGO = [{
  stationName: 'wakafxl',
  logo: 'src/images/app/xl-logo.png',
}, {
  stationName: 'default',
  logo: logo,
}, {
  stationName: 'noplastic',
  logo: logo,
}, {
  stationName: 'dibawahtangga',
  logo: dibawahtangga,
}, {
  stationName: 'workcoffeebdg',
  logo: workcoffee,
}, {
  stationName: 'workcoffeejkt',
  logo: workcoffee,
}, {
  stationName: 'sman3bdg',
  logo: sman3bdg,
}, {
  stationName: 'sman8jkt',
  logo: sman8jkt,
}, {
  stationName: 'mrtj',
  logo: mrtj,
}, {
  stationName: 'bluebird1',
  logo: bluebird,
}, {
  stationName: 'smkn3denpasar',
  logo: smkn3denpasar,
}, {
  stationName: 'bluebird2',
  logo: bluebird,
}, {
  stationName: 'aqua_wwf1',
  logo: waterForum,
}, {
  stationName: 'aqua_wwf2',
  logo: waterForum,
},{
  stationName: 'kedaiutama',
  logo: kedaiutama,
},{
  stationName: 'smk_merdeka',
  logo: smkMerdeka,
},{
  stationName: 'neduh',
  logo: neduh,
}];

export const DATE_FORMAT = 'D MMMM YYYY HH:mm';

